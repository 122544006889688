const config = {
  baseUrl: "https://qa-apioldravian.appinsnap.com/",

  authPath: "api/user",
  rolePath: "api/role",
  complaintPath: "api/complaint",
  membershipPath: "api/membership",
  bannerPath: "api/banner",
  donationPath: "api/donation",
  announcementPath: "api/announcement",
  eventPath: "api/event",
  dashboardPath: "api/dashboard",
  categoryPath: "api/category",
  galleryPath: "api/gallery",
};

export default config;
